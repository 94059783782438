@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700,800|Montserrat:400,500,700|Roboto:100,300,400");

.app {
  /* prevent text selection */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  .no-scroll {
    height: 100vh;
    overflow: hidden;
  }
}

.spinner-border {
  width: 1rem !important;
  margin-left: 1em;
}

a {
  font-weight: 500;
}

* {
  font-family: montserrat;
  color: #404040;
}

@media screen and (max-width: 576px) {
  * {
    font-size: 14px;
    line-height: 23px;
  }
}

.container-fluid {
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  background: black;
  // background-color: #e9ecef;
  &.light {
    background: white;
  }
}

.no-pad {
  padding: 0;
}

.no-pad-right {
  padding-right: 0;
}

.inline {
  display: inline-block;
}

.m-bold {
  font-weight: 500;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container-fluid::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-fluid {
  overscroll-behavior-y: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.search-bar {
  width: 50%;
  border: none;
  padding: 1em;
  border-radius: 0.5em;
  height: 3em;
}

h2, h3 {
  color: #424242;
}

input:focus, button:focus {
  outline: none;
}

[contenteditable] {
  border: none;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #888;
  font-style: italic;
}

.btn-link {
  color: #007bff;
}

// .btn-primary {
//   // background-color: #3e4c59 !important;
//   border-color: #3e4c59 !important;
// }

.btn-primary:focus {
  outline: none;
}

.btn-primary:hover {
  background-color: #3e4c59 !important;
  border-color: #3e4c59 !important;
}

.btn-danger {
  background-color: #db5a4260 !important;
  border-color: #db5a4260 !important;
}

.btn-secondary {
  background-color: transparent;
  color: #888;
}

.alert {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 1001;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  height: max-content;
  text-align: center;
}

.emoji-menu {
  input {
    width: 100%;
    border: 1px solid #aeaeae;
    margin-bottom: 1em;
  }
}

.full-width {
  width: 100%;
}

.alert-error {
  top: 25em;
  background-color: #dc3545!important;
  color: white;
  font-weight: 500;
  opacity: 0.8;
}

.error-title {
  color: red;
}

.stack {
  word-wrap: break-word;
}

.spinner-border {
  vertical-align: middle;
}


.loading-ell {
  font-size: 20px;
  color: #313131 !important;
}

.loading-ell:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 0.9em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 0.9em;
  }
}